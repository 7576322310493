<script>
    import Aws from '@/services/Aws';
    import { required, integer, minValue} from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'


    export default {
        components:{
            validationMessages
        },
        props: ['autoScalingGroupData'],
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                showLoader:false,
                modalTitle:'',
                MinSize: 0,
                MaxSize: 0,
                DesiredCapacity: 0
            };
        },
        validations: {
            MinSize: {
                required,
                integer,
                minValue: minValue(1)
            },
            MaxSize:{
                required,
                integer,
                minValue: minValue(1)
            },
            DesiredCapacity:{
                required,
                integer,
                minValue: minValue(1)
            },
        },
        methods: {

            async editAutoScalingGroup(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch();

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Aws.editAutoScalingGroup({
                            MinSize: this.MinSize,
                            MaxSize: this.MaxSize,
                            AutoScalingGroupName: this.autoScalingGroupData.AutoScalingGroupName,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Auto Scaling Group successfully edited");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error);
                        }).finally(() => {
                            this.refreshData();
                            this.tryingToEdit = false;
                        });
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error);
                    }
                }

                this.tryingToEdit = false;
            },

            refreshData() {
                this.$emit('onRefresh')
            },

            closeModal() {
                this.showModal = false;
            },

            initialData(){
                this.MinSize = this.autoScalingGroupData.MinSize
                this.MaxSize = this.autoScalingGroupData.MaxSize
                this.DesiredCapacity = this.autoScalingGroupData.DesiredCapacity
            }
        }
    };
</script>

<template>
    <b-modal @shown="initialData" v-model="showModal" id="edit_autoscalinggroup" title="Edit Auto Scaling Group" title-class="font-18" centered>
        <form @submit.prevent="editAutoScalingGroup">

            <div class="row">
                <div class="col-sm-12 text-center">
                    <h4>{{autoScalingGroupData.AutoScalingGroupName}}</h4>
                </div>
                <div class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <label for="minSize">Min Size:</label>
                        <input type="number" id="minSize"
                               class="form-control"
                               v-model.number="MinSize"
                               min="1" max="10"
                               :class="{
                                  'is-invalid': submitted && $v.MinSize.$error,
                                }"
                        />
                        <validationMessages v-if="submitted" :fieldName="'Min Size'" :validationName="$v.MinSize"></validationMessages>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <label for="maxSize">Max Size:</label>
                        <input type="number" id="maxSize"
                               class="form-control"
                               v-model.number="MaxSize"
                               min="1" max="100"
                               :class="{
                                  'is-invalid': submitted && $v.MaxSize.$error,
                                }"
                        />
                        <validationMessages v-if="submitted" :fieldName="'Max Size'" :validationName="$v.MaxSize"></validationMessages>
                    </div>
                </div>

                <!--<div class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <label for="desiredCapacity">Desired Capacity:</label>
                        <input type="number" id="desiredCapacity"
                               class="form-control"
                               v-model.number="DesiredCapacity"
                               min="1" max="100"
                               :class="{
                                  'is-invalid': submitted && $v.DesiredCapacity.$error,
                                }"
                        />
                        <validationMessages v-if="submitted" :fieldName="'Desired Capacity'" :validationName="$v.DesiredCapacity"></validationMessages>
                    </div>
                </div>-->
            </div>
        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editAutoScalingGroup" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Edit</b-button>
        </template>
    </b-modal>
</template>